studenStaffConfig = {};
var isDislikePopupOpen = false;

function setApiUrlsForStudentStaff(apiData){
	studenStaffConfig = apiData;
	//console.log('studenStaffConfig: ', studenStaffConfig);
}

 function getChatbotIdForStudentAzureBot() {
	$.ajax({
		type: "POST",
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		url: studenStaffConfig.getChatbotId,
		data: {
			chatbot_name: 'student'
		},
		success: function(response) {
			studenStaffConfig.chatBotId = response.chatbot_id;
			sessionStorage.setItem('chatbotIdStudent', response.chatbot_id)
		}
	});

}

function checkSASCookiesForStudentAzureChatbot() {
	$.ajax({
		type: "POST",
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		url: studenStaffConfig.sasCookies,
		success: function(response) {
			localStorage.setItem('sas_token', response.sas_token);
		}
	});
} 

function SasTokenForStudentAzureChatbot(){
	$.ajax({
		type: "POST",
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		url: studenStaffConfig.sasToken,
		success: function(response) {
			localStorage.setItem('sas_token', response.sas_token);
		}
	});
}

function refreshChatStudentAzureChatbot(e, id){
	$.ajax({
		type: "POST",
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		url: studenStaffConfig.refreshChat,
		data: {
			chatbot_id: studenStaffConfig.chatBotId,
			session_id: localStorage.getItem('session_uuid'),
		},
		success: function (response) {
			
			var bot_msg = response.text_response;
			
			setTimeout(function () {
				$('.hk-chatbot-popup .chatbot-popup-body ul.list-unstyled').empty();

				// Create and append a new list item
				var welcome_message = $(`<li class="media received">
											<div class="avatar avatar-xs avatar-soft-primary avatar-icon avatar-rounded">
												<div class="avatar avatar-xs avatar-rounded">
													<img style="width: 33px;" src='https://genaichatbotdev.blob.core.windows.net/chatbots/student/assets/images/wally.svg?sp=r&amp;st=2024-11-04T12:52:12Z&amp;se=2026-03-31T20:52:12Z&amp;sv=2022-11-02&amp;sr=b&amp;sig=z3AhpccPAXOLftLrlKL7NZl3hnpYclfAKh6J12gDoL8%3D'
					style="mix-blend-mode: color;" alt="user" class="avatar-img">
												</div>
											</div>
											<div class="media-body">
												<div class="msg-box">
													<div>
														<p>Hello! How can I assist you today?</p>
													</div>
												</div>
												
											</div>
										</li>`);
				$('.hk-chatbot-popup ul.list-unstyled').append(welcome_message);
				var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
				chatBox.scrollTop = chatBox.scrollHeight;
			}, 0);
		}
	});
		
}

async function inputMsgChatPopupStudentAzureChatbot(e, id){
	if ((e.which == 13)&&(!$(id).val().length == 0)) {
		const messageInputField = document.getElementById("input_msg_chat_popup");
		messageInputField.disabled = true;
		inputTextStatus(false);
		message = messageInputField.value.trim();
		$('<li class="media sent"><div class="media-body"><div class="msg-box"><div><p>' + $(id).val() + '</p> </div></div></div></li>').appendTo(".hk-chatbot-popup  ul.list-unstyled");
		$(id).val('');
		
		$(`<li class="media received typing-wrap">
			<div class="avatar avatar-xs avatar-soft-purple avatar-rounded"><img style="width: 33px;" src='https://genaichatbotdev.blob.core.windows.net/chatbots/student/assets/images/wally.svg?sp=r&amp;st=2024-11-04T12:52:12Z&amp;se=2026-03-31T20:52:12Z&amp;sv=2022-11-02&amp;sr=b&amp;sig=z3AhpccPAXOLftLrlKL7NZl3hnpYclfAKh6J12gDoL8%3D'
					style="mix-blend-mode: color;" alt="user" class="avatar-img"></div>
			<div class="media-body">
				<div class="msg-box">
					<div>
						<p>Looking for <strong>${message}</strong></p>
					</div>
				</div>
				<div class="msg-box">
					<div>
						<div class="typing">
							<div class="dot"></div>
							<div class="dot"></div>
							<div class="dot"></div>
						</div>
					</div>
				</div>
			</div>
		</li>`).appendTo('.hk-chatbot-popup .chatbot-popup-body ul.list-unstyled');
		
		var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
		chatBox.scrollTop = chatBox.scrollHeight;
		
		// Get the current date and time
		var now = new Date();
		var formatted_date = now.toISOString().slice(0, 19).replace(/-/g, "_").replace("T", "_").replace(/:/g, "_");
		convoId = localStorage.getItem('session_uuid') + "_" + formatted_date;
		const convo_id = convoId;
		
			const response = await fetch(studenStaffConfig.searchApiRequest, {
				method: "POST",
				headers: {
				"Content-Type": "application/json"
				},
				body: JSON.stringify({
					chatbot_id: studenStaffConfig.chatBotId,
					session_id: localStorage.getItem('session_uuid'),
					message: message,
					userEmail: sessionStorage.getItem('userEmail'),
					origin: "Student",
					convo_id: convo_id
				}),
			});
		inputTextStatus(false);

		const decoder = new TextDecoder();
		const reader = response.body.getReader();

		let text_response = ""
		var rephrased_query = message;
			//console.log('rephrased_query: ', rephrased_query);
		while(true) {
			const {done, value} = await reader.read();
			if (done) {
				let inputText = text_response;
				if(inputText.includes("$STATUS_CODE_BEGIN$200$STATUS_CODE_END$")){
					inputText = inputText.replace("$STATUS_CODE_BEGIN$200$STATUS_CODE_END$","")
				}
				if (inputText.includes('<!-- Cached Response -->') || inputText.includes('Cached Response')) {
					inputText = removeCitationLinksFromResponse(inputText);
				}
				// Regular expression pattern to find text enclosed in square brackets
				const citationPattern = /\[([^\]]+)\]/g;

				// Create an object to store unique citations with hyperlinks
				const citations = {};

				// Track the citation count
				let citationCount = 1;

				// Replace citations with hyperlinks and store them in the citations object
				const processedText = inputText.replace(citationPattern, (match, text) => {
					// Check if the text already has a citation number
					if (!citations[text]) {
						if ((text.startsWith("http")) || (text.startsWith("www") || (text.startsWith('academics.')) || (text.startsWith('academicanswers.')) || (text.startsWith('academicguides.')))){
							citations[text] = {
							text: text,
							hyperlink: `${text}`,
							citationNumber: citationCount++
							};
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;

						} else if (text.endsWith(".pdf")) {
							citations[text] = {
								text: text,
								hyperlink: `${studenStaffConfig.citationPDF}-${studenStaffConfig.chatBotId}/${text}?${localStorage.getItem('sas_token')}`,
								citationNumber: citationCount++
							};
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;

						} else if ((text.startsWith("General")) || (text.startsWith("Database"))) {
							citations[text] = {
								text: text,
								hyperlink: `${text}`,
								citationNumber: citationCount++
							};
							return `<a target="_blank" class="badge badge-sm badge-light" style="margin-left:2px;" disabled>${citations[text].citationNumber}</a>`;
						} else {
							return ``
						}
					}
					else {
						if (text.startsWith("http")){
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;
						} else if (text.endsWith(".pdf")) {
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;
						} else if ((text.startsWith("General")) || (text.startsWith("Database"))) {
							return `<a target="_blank" class="badge badge-sm badge-light" style="margin-left:2px;" disabled>${citations[text].citationNumber}</a>`;
						} else {
							return ``
						}
					}
				});

				// Create a JSON object for the citations
				const citationsJSON = citations;
				let citationText = "";
				const pdfCitations = [];
				var websiteCitations = [];
				var unverified = [];
				const citationPayloadLinks = [];
				var originalCitation = [];

				for (const key in citationsJSON) {
					if (citationsJSON.hasOwnProperty(key)) {
						const citation = citationsJSON[key];
						originalCitation.push(citation);
						const citationLink = citation.hyperlink;
						citationPayloadLinks.push(citationLink);
						const citationText = citation.text.replace("https://","").slice(0, 30);
						if (citationLink.endsWith(".pdf")) {
							pdfCitations.push(`<a href="${citationLink}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${citation.citationNumber}. ${citationText}...</a>`);
						} else if (citationLink.startsWith("General")) {
							unverified.push(`<a class="badge badge-light mt-2" style="margin-left:2px;" target="_blank" disabled>${citation.citationNumber}. ${citationText}</a>`);
						} else if (citationLink.startsWith("Database")) {
							pdfCitations.push(`<a class="badge badge-light mt-2" style="margin-left:2px;" target="_blank" disabled>${citation.citationNumber}. ${citationText}</a>`);
						} else if (citationLink.startsWith("http")){
							websiteCitations.push(`<a href="${citationLink}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${citation.citationNumber}. ${citationText}...</a>`);
						}
					}
				}
				var pdfCitationText = pdfCitations.length > 0 ? `<div id="pdf_citation_msg_box_${convo_id}" class="msg-box" style="display:none"><span class="p-1 border border-light rounded-5"><p style="font-size:10px; text-align: left;">PDF citation</p>${pdfCitations.join("")}</span></div>` : "";
				console.log('pdfCitationText: ', pdfCitationText);
				var websiteCitationText = websiteCitations.length > 0 ? `<div id="enrollment_citation_msg_box_${convo_id}" class="msg-box" style="display:none"><span class=""><p style="font-size:10px; text-align: left;">Walden Owned Public Data Source</p>${websiteCitations.join("")}</span></div>` : "";
				var unverifiedText = unverified.length > 0 ? `<div id="enrollment_unverified_citation_msg_box_${convo_id}" class="msg-box" style="display:none"><span class=""><p style="font-size:10px; text-align: left;">Walden UnVerified Public Data Source</p>${unverified.join("")}</span></div>` : "";
				setTimeout(function () {

					
					var typingBubble = document.querySelector('.media.received.typing-wrap');
					if (typingBubble) {
						typingBubble.parentNode.removeChild(typingBubble);
					}

					var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
					chatBox.scrollTop = chatBox.scrollHeight;

					const textElement = document.getElementById('streambubble');
					var streamingBubble = document.querySelector('.media.received.streambubble');
					if (streamingBubble) {
						streamingBubble.parentNode.removeChild(streamingBubble);
					}
					var checkFeedbackOptions = ['Response is too slow', 'Response is partially or completely incorrect (Please provide more details in the comments below)', 'Data source needs to be added or updated (Please provide more details in the comments below)'];
					$(`	
					<li class="media received">
						<div class="avatar avatar-xs avatar-soft-purple avatar-rounded"><img style="width: 33px;" src='https://genaichatbotdev.blob.core.windows.net/chatbots/student/assets/images/wally.svg?sp=r&amp;st=2024-11-04T12:52:12Z&amp;se=2026-03-31T20:52:12Z&amp;sv=2022-11-02&amp;sr=b&amp;sig=z3AhpccPAXOLftLrlKL7NZl3hnpYclfAKh6J12gDoL8%3D'
					style="mix-blend-mode: color;" alt="user" class="avatar-img"></div>
							<div class="media-body"><div class="msg-box">
								<div>
									<p id="${convo_id}_query">Generated results for <strong>${rephrased_query}</strong></p>
								</div>
								
							</div>
							<div class="msg-box">
								<div id="${convo_id}_response">
									<p id="${convo_id}_answer">${processedText}</p>
								</div>

							</div>
							<div style="border: 1px solid #D0DFF6;" class="mt-2">
								<div id="action-buttons">
								<div class="msg-action" style="background:transparent;">
								<p id="${convo_id}_action_update" value='' hidden></p>
								<button id="like_convo" data-convo_id='${convo_id}' data-convo_action='like' class="btn btn-icon btn-rounded btn-flush-primary btn-lg"  data-bs-toggle="dropdown"><span class="icon"><i class="fa fa-thumbs-up"></i></span></button>
								<div class="dropdown-menu dropdown-menu-end">
									<a class="dropdown-item">
										<label for="convo_feedback">Would you like to share a feedback?</label>
										<textarea class="form-control" data-convo_id='${convo_id}' id="${convo_id}_convo_feedback_like" type="text"></textarea>
										<button id="send_like_feedback" data-convo_id='${convo_id}' class="btn  btn-custom  btn-success icon-wthot-bg btn-rounded mt-1" style="float: inline-end;"><span><span>Send</span><span class="icon"><i class="fa fa-angle-right"></i> </span></span></button>
									</a>
								</div>
								<button id="dislike_convo" data-convo_id='${convo_id}' data-convo_action='dislike' class="btn btn-icon btn-rounded btn-flush-primary"><span class="icon"><i class="fa fa-thumbs-down"></i></span></button>
								<div id="dislikeCategory_${convo_id}" class="dropdown-menu dropdown-menu-end dislikeCategory">
								<a class="dropdown-item">
								<label for="convo_feedback">Would you like to share a feedback?</label>
								<div class="form-check">
								<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox1_${convo_id}" type="checkbox" value="${checkFeedbackOptions[0]}">
								<label class="form-check-label" id="label1" for="checkbox1">
								${checkFeedbackOptions[0]}
								</label>
								  </div>
								  <div class="form-check">
								<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox2_${convo_id}" type="checkbox" value="${checkFeedbackOptions[1]}">
								<label class="form-check-label" id="label2" for="checkbox2">
								Response is partially or completely incorrect <br>
               		 			<span>(Please provide more details in the comments below)</span>
								</label>
								  </div>
								  <div class="form-check">
								<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox3_${convo_id}" type="checkbox" value="${checkFeedbackOptions[2]}">
								<label class="form-check-label" id="label3" for="checkbox3">
								Data source needs to be added or updated <br>
                				<span>(Please provide more details in the comments below)</span>
								</label>
								  </div>
								  
								  
								  <textarea class="form-control" data-convo_id='${convo_id}' id="${convo_id}_convo_feedback_dislike_std" type="text" placeholder="Provide additional feedback"></textarea>
								<button id="send_dislike_feedback" data-convo_id='${convo_id}' class="btn  btn-custom  btn-success icon-wthot-bg btn-rounded mt-1" style="float: inline-end;" disabled><span><span>Send</span><span class="icon"><i class="fa fa-angle-right"></i> </span></span></button>
							    </a>
									
								</div>
								
								<a id="enrollment_citation_btn_${convo_id}" data-convo_id='${convo_id}' style="margin: 2px 10px 2px 2px; margin-button: 15px; border: none; cursor: pointer"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16" style="margin-right: 5px;">
								<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
								<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
							  </svg>Citation</a>
							  <a id="copy_response_btn_env" data-convo_id='${convo_id}' style="margin: 2px 10px 2px 2px; margin-button: 15px; border: none; cursor: pointer"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16" style="margin-right: 5px;">
							  <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
							  </svg>Copy Response</a>
							<a id="disclaimer" data-convo_id='${convo_id}' data-convo_action='disclaimer'  data-bs-toggle="dropdown"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
							<path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
							<path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
						  </svg>Disclaimer</a>
						  <div class="dropdown-menu dropdown-menu-end">
						  <a class="dropdown-item">
						  <p class="form-control" data-convo_id='${convo_id}' id="${convo_id}_disclaimer" type="text" style="border-color: red; white-space: normal;">
						  <strong>Disclaimer:</strong> While interacting in this chat window, you are interacting
						  with a chatbot that uses generative AI, or materials created by generative
						  AI. If you are ever transferred to a real person, our human representative
						  will introduce themselves during the chat. If you have any questions
						  regarding this notice, please email Algovernance@adtalem.com
					  </p>
						  </a>
								</div>
							</div>
								</div>

							</div>
							<div id="${convo_id}_cached_response_text" data-convo_id='${convo_id}' style="display: none; size: 16px" class="mt-2">
							<b>${window.env.studentAsStaffConfig.cachedResponseConstant}</b>
							</div>
							<div id="${convo_id}_apology_text" data-convo_id='${convo_id}' style="display: none; font-family: Halant; size: 16px" class="mt-2">
							<p>Sorry for the inadequate response. As a bot, I will use this feedback to get better!</p>
							</div>
							<div class="mt-2" id="${convo_id}_citationstitles">
							
								${pdfCitationText}
								<div id="${convo_id}_verifiedCitationstitles">
								${websiteCitationText}
								</div>
								<div id="${convo_id}_unVerifiedCitationstitles">
								${unverifiedText}
								</div>
							</div>
						</div>
					</li>`).appendTo(".hk-chatbot-popup .chatbot-popup-body ul.list-unstyled");
					var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
					chatBox.scrollTop = chatBox.scrollHeight;
					if (inputText.includes('<!-- Cached Response -->') || inputText.includes('Cached Response')) {
						findCatchResponse(convo_id);
					}
				}, 0);
				if (citationPayloadLinks.length !== 0) {
					const response = await fetch(studenStaffConfig.citationPayloadLink, {
						method: "POST",
						headers: {
							"Content-Type": "application/json"
						},
						body: JSON.stringify({
							chatbot_id: studenStaffConfig.chatBotId,
							convo_id: convo_id,
							sourcefiles: citationPayloadLinks
						}),
					});
					if (response.ok) {
						let responseData = await response.json();
						//websiteCitations = [];
						originalWebsiteCitation = [];
						websiteCitationText = "";
						var specificCitationsText = Object.keys(responseData.citationcategory_group);
						var newCitations = responseData.citationcategory_group;
						if (specificCitationsText.includes("Citation from Walden Resources")) {
							originalWebsiteCitation = [];
							var newWebsiteCitations = responseData.citationcategory_group["Citation from Walden Resources"];
							console.log('newWebsiteCitations: ', newWebsiteCitations);
							var getCitationText = document.getElementById("enrollment_citation_msg_box_" + convo_id);
							var getPDFCitation = document.getElementById("pdf_citation_msg_box_" + convo_id);
							console.log('getPDFCitation: ', getPDFCitation);
							console.log('getCitationText: ', getCitationText);
							if (getPDFCitation) {
								var pdfLinkElement = getPDFCitation.querySelectorAll('a');
        						console.log('href pdf linkElement: ', pdfLinkElement);
								if (getCitationText) {
								var getSpanFromWebsiteCitation = getCitationText.querySelector('span');
								console.log('getSpanFromWebsiteCitation: ', getSpanFromWebsiteCitation);
								pdfLinkElement.forEach(el => getSpanFromWebsiteCitation.appendChild(el));
								} else {
								// 	getPDFCitation.id = "enrollment_citation_msg_box_" + convo_id;
								// 	var getSpanFromWebsiteCitation = getCitationText.querySelector('span');
								// console.log('getSpanFromWebsiteCitation: ', getSpanFromWebsiteCitation);
								// pdfLinkElement.forEach(el => getSpanFromWebsiteCitation.appendChild(el));
								getpdfPTag = getPDFCitation.querySelector('p');
								getpdfPTag.textContent = 'Citation from Walden Resources';
								}
								
							}
							var getHrefFromWebsiteCitation = getCitationText.querySelectorAll('a');
							var getHrefTagArray = Array.from(getHrefFromWebsiteCitation);
							//pdfLinkElement.forEach(el => getHrefTagArray.push(el));
							console.log('getHrefTagArray: ', getHrefTagArray);
							console.log('getCitationText after adding pdf ele: ', getCitationText);
							console.log('originalCitation: ', originalCitation);
							 
								for (let i = 0; i < getHrefTagArray.length; i++) {
									for (let j = 0; j < newWebsiteCitations.length; j++) {
										const citation = newWebsiteCitations[j];
										const url = citation.URL;
										const title = citation.Title;
										if (getHrefTagArray[i].href === url) {
											// var number = extractNumberAndPrependTitle(url, websiteCitations);
											// console.log('number : ', number);
											const shortenedTitle = title.substring(0, 30); 
											console.log('getHrefTagArray[i].innerText: ', getHrefTagArray[i].innerText);
											getHrefTagArray[i].innerText = (i+1) +'.' + shortenedTitle + '...';
											getHrefTagArray[i].setAttribute("title", title);
											break;
										} else {
											// var number = extractNumberAndPrependTitle(url, websiteCitations);
											const shortenedUrl = url.substring(0, 30);
											getHrefTagArray[i].innerText = (i+1) +'.' + shortenedUrl + '...';
											getHrefTagArray[i].setAttribute("title", url);
										}
									}
								}
							
							
								// for (let i=0; i<newWebsiteCitations.length; i++) {
								// 			originalWebsiteCitation.push(newWebsiteCitations[i].Title);
								// 			let title = newWebsiteCitations[i].Title.substring(0, 30);
								// 			websiteCitations.push(`<a href="${newWebsiteCitations[i].URL}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${i+1}. ${title}...</a>`);
								// 	}
							var getCitationText = document.getElementById("enrollment_citation_msg_box_"+convo_id);
							var getPTag = getCitationText.querySelector('p');
							getPTag.textContent = 'Citation from Walden Resources';
							//addCitationTitle(convo_id, websiteCitations, originalWebsiteCitation, 'Walden specific citations');
						} 
						 if (specificCitationsText.includes("Citation from Public Resources (not verified by Walden)")) {
							originalWebsiteCitation = [];
							var newUnverifiedCitations = responseData.citationcategory_group["Citation from Public Resources (not verified by Walden)"];
							var getUnverifiedCitationText = document.getElementById("enrollment_unverified_citation_msg_box_" + convo_id);
							var getUnverifiedHrefTag = getUnverifiedCitationText.getElementsByClassName('badge badge-light mt-2');
							var getUnverifiedHrefTagArray = Array.from(getUnverifiedHrefTag);
							console.log('getHrefTagArray: ', getUnverifiedHrefTagArray);
							for (let i = 0; i < getUnverifiedHrefTagArray.length; i++) {
								for (let j = 0; j < newUnverifiedCitations.length; j++) {
									const citation = newUnverifiedCitations[j];
									const url = citation.URL;
									const title = citation.Title;
									if (getUnverifiedHrefTagArray[i].href === url) {
										// var number = extractNumberAndPrependTitle(url, websiteCitations);
										// console.log('number : ', number);
										const shortenedTitle = title.substring(0, 30); 
										console.log('getHrefTagArray[i].innerText: ', getUnverifiedHrefTagArray[i].innerText);
										getUnverifiedHrefTagArray[i].innerText = (i+1) +'.' + shortenedTitle + '...';
										console.log('index: ', i);
										getUnverifiedHrefTagArray[i].setAttribute("title", title);
										break;
									} else {
										// var number = extractNumberAndPrependTitle(url, websiteCitations);
										const shortenedUrl = url.substring(0, 30);
										getUnverifiedHrefTagArray[i].innerText = (i+1) +'.' + shortenedUrl + '...';
										getUnverifiedHrefTagArray[i].setAttribute("title", url);
									}
								}
							}
							// for (let i=0; i<newUnverifiedCitations.length; i++) {
							// 	originalWebsiteCitation.push(newUnverifiedCitations[i].Title);
							// 	let title = newUnverifiedCitations[i].Title.substring(0, 30);
							// 	unverified.push(`<a class="badge badge-light mt-2" style="margin-left:2px;" target="_blank" disabled>${i+1}. ${title}</a>`);
							// }
							var unverifiedText = document.getElementById("enrollment_unverified_citation_msg_box_"+convo_id);
							var getPTag = unverifiedText.querySelector('p');
							getPTag.textContent = 'Citation from Public Resources (not verified by Walden)';
							//addCitationTitle(convo_id, unverified, originalWebsiteCitation, 'Unverified citations');
						}
						// for (let i=0; i<newCitations.length; i++) {
						// 	originalWebsiteCitation.push(newCitations[i].Title);
						// 	let title = newCitations[i].Title.substring(0, 30);
						// 	websiteCitations.push(`<a href="${newCitations[i].URL}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${i+1}. ${title}...</a>`);
						// }
						// var getCitationText = document.getElementById("enrollment_citation_msg_box_"+convo_id);
						// var getPTag = getCitationText.querySelector('p');
						// getPTag.textContent = specificCitationsText;
						// addCitationTitle(convo_id, websiteCitations, originalWebsiteCitation);
					} else {
						console.error('Error:', response.status);
					}
				} 

				break
			} 
			else {
				// text_response += decoder.decode(value);
				let inputText = text_response;
				var rq_regex = /\$SRQ\$(.*?)\$ERQ\$/;
				var rq_match = decoder.decode(value).match(rq_regex);
				// console.log(rq_match);
				if (rq_match && rq_match.length > 1) {
					rephrased_query = rq_match[1];
					inputText = text_response;
				} else {
					text_response += decoder.decode(value);
					inputText = text_response;
				}
				if (inputText.includes('<!-- Cached Response -->') || inputText.includes('Cached Response')) {
					inputText = removeCitationLinksFromResponse(inputText);
				}

				var regex = /\$FINAL_CONTEXT\$(.*)/;
				// Extracting the text after $FINAL_CONTEXT$ using the regular expression
				var match = decoder.decode(value).match(regex);

				// Check if there is a match and extract the text
				if (match && match.length > 1) {
					var extractedText = match[1].trim();
					text_response = extractedText;
					inputText = text_response;
				} else {
					console.log("Text after $FINAL_CONTEXT$ not found!");
				}
				
				//console.log("rephrased_query in else: ",rephrased_query);
				// Regular expression pattern to find text enclosed in square brackets
				const citationPattern = /\[([^\]]+)\]/g;

				// Create an object to store unique citations with hyperlinks
				const citations = {};

				// Track the citation count
				let citationCount = 1;

				// Replace citations with hyperlinks and store them in the citations object
				const processedText = inputText.replace(citationPattern, (match, text) => {
					// Check if the text already has a citation number
					if (!citations[text]) {
						if ((text.startsWith("http")) || (text.startsWith("www") || (text.startsWith('academics.')) || (text.startsWith('academicanswers.')) || (text.startsWith('academicguides.')))){
							citations[text] = {
							text: text,
							hyperlink: `${text}`,
							citationNumber: citationCount++
							};
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;

						} else if (text.endsWith(".pdf")) {
							citations[text] = {
								text: text,
								hyperlink: `${studenStaffConfig.citationPDF}-${studenStaffConfig.chatBotId}/${text}?${localStorage.getItem('sas_token')}`,
								citationNumber: citationCount++
							};
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;

						} else if ((text.startsWith("General")) || (text.startsWith("Database"))) {
							citations[text] = {
								text: text,
								hyperlink: `${text}`,
								citationNumber: citationCount++
							};
							return `<a target="_blank" class="badge badge-sm badge-light" style="margin-left:2px;" disabled>${citations[text].citationNumber}</a>`;
						} else {
							return ``
						}
					}
					else {
						if (text.startsWith("http")){
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;
						} else if (text.endsWith(".pdf")) {
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;
						} else if ((text.startsWith("General")) || (text.startsWith("Database"))) {
							return `<a target="_blank" class="badge badge-sm badge-light" style="margin-left:2px;" disabled>${citations[text].citationNumber}</a>`;
						} else {
							return ``
						}
					}
				});

				// Create a JSON object for the citations
				const citationsJSON = citations;
				let citationText = "";
				const pdfCitations = [];
				const websiteCitations = [];
				const unverified = [];

				for (const key in citationsJSON) {
					if (citationsJSON.hasOwnProperty(key)) {
						const citation = citationsJSON[key];
						const citationLink = citation.hyperlink;
						const citationText = citation.text.replace("https://","").slice(0, 30);
						if (citationLink.endsWith(".pdf")) {
							pdfCitations.push(`<a href="${citationLink}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${citation.citationNumber}. ${citationText}...</a>`);
						} else if (citationLink.startsWith("General")) {
							unverified.push(`<a class="badge badge-light mt-2" style="margin-left:2px;" target="_blank" disabled>${citation.citationNumber}. ${citationText}</a>`);
						} else if (citationLink.startsWith("Database")) {
							pdfCitations.push(`<a class="badge badge-light mt-2" style="margin-left:2px;" target="_blank" disabled>${citation.citationNumber}. ${citationText}</a>`);
						} else if (citationLink.startsWith("http")){
							websiteCitations.push(`<a href="${citationLink}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${citation.citationNumber}. ${citationText}...</a>`);
						}
					}
				}
				const pdfCitationText = pdfCitations.length > 0 ? `<div id="pdf_citation_msg_box_${convo_id}" class="msg-box" style="display:none"><span class="p-1 border border-light rounded-5"><p style="font-size:10px; text-align: left;">PDF citation</p>${pdfCitations.join("")}</span></div>` : "";
				const websiteCitationText = websiteCitations.length > 0 ? `<div class="msg-box" style="display:none"><span class=""><p style="font-size:10px; text-align: left;">Walden Owned Public Data Source</p>${websiteCitations.join("")}</span></div>` : "";
				const unverifiedText = unverified.length > 0 ? `<div class="msg-box"><span class=""><p style="font-size:10px; text-align: left;">Walden UnVerified Public Data Source</p>${unverified.join("")}</span></div>` : "";

				setTimeout(function () {

					
					var typingBubble = document.querySelector('.media.received.typing-wrap');
					if (typingBubble) {
						typingBubble.parentNode.removeChild(typingBubble);
					}

					var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
					chatBox.scrollTop = chatBox.scrollHeight;

					const textElement = document.getElementById('streambubble');
					var streamingBubble = document.querySelector('.media.received.streambubble');
					if (streamingBubble) {
						streamingBubble.parentNode.removeChild(streamingBubble);
					}
					var checkFeedbackOptions = ['Response is too slow', 'Response is partially or completely incorrect (Please provide more details in the comments below)', 'Data source needs to be added or updated (Please provide more details in the comments below)'];
					
					$(`	
					<li class="media received streambubble">
						<div class="avatar avatar-xs avatar-soft-purple avatar-rounded"><img style="width: 33px;" src='https://genaichatbotdev.blob.core.windows.net/chatbots/student/assets/images/wally.svg?sp=r&amp;st=2024-11-04T12:52:12Z&amp;se=2026-03-31T20:52:12Z&amp;sv=2022-11-02&amp;sr=b&amp;sig=z3AhpccPAXOLftLrlKL7NZl3hnpYclfAKh6J12gDoL8%3D'
					style="mix-blend-mode: color;" alt="user" class="avatar-img"></div>
							<div class="media-body"><div class="msg-box">
								<div>
									<p id="${convo_id}_query">Generating results for <strong>${rephrased_query}</strong></p>
								</div>
								
							</div>
							<div class="msg-box">
								<div id="${convo_id}_response">
									<p id="${convo_id}_answer">${processedText}</p>
									<div class="typing" style="justify-content: right;padding-top: 10px;">
										<div class="dot"></div>
										<div class="dot"></div>
										<div class="dot"></div>
									</div>
								</div>
								
							</div>
							<div style="border: 1px solid #D0DFF6;" class="mt-2">
								<div id="action-buttons">
								<div class="msg-action" style="background:transparent;">
								<p id="${convo_id}_action_update" value='' hidden></p>
								<button id="like_convo" data-convo_id='${convo_id}' data-convo_action='like' class="btn btn-icon btn-rounded btn-flush-primary btn-lg"  data-bs-toggle="dropdown"><span class="icon"><i class="fa fa-thumbs-up"></i></span></button>
								<div class="dropdown-menu dropdown-menu-end">
									<a class="dropdown-item">
										<label for="convo_feedback">Would you like to share a feedback?</label>
										<textarea class="form-control" data-convo_id='${convo_id}' id="${convo_id}_convo_feedback_like" type="text"></textarea>
										<button id="send_like_feedback" data-convo_id='${convo_id}' class="btn  btn-custom  btn-success icon-wthot-bg btn-rounded mt-1" style="float: inline-end;"><span><span>Send</span><span class="icon"><i class="fa fa-angle-right"></i> </span></span></button>
									</a>
								</div>
								<button id="dislike_convo" data-convo_id='${convo_id}' data-convo_action='dislike' class="btn btn-icon btn-rounded btn-flush-primary"><span class="icon"><i class="fa fa-thumbs-down"></i></span></button>
								<div id="dislikeCategory_${convo_id}" class="dropdown-menu dropdown-menu-end dislikeCategory">
									<a class="dropdown-item">
										<label for="convo_feedback">Would you like to share a feedback?</label>
										<div class="form-check">
										<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox1_${convo_id}" type="checkbox" value="${checkFeedbackOptions[0]}">
										<label class="form-check-label" id="label1" for="checkbox1">
										${checkFeedbackOptions[0]}
										</label>
										  </div>
										  <div class="form-check">
										<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox2_${convo_id}" type="checkbox" value="${checkFeedbackOptions[1]}">
										<label class="form-check-label" id="label2" for="checkbox2">
										Response is partially or completely incorrect <br>
               		 					<span>(Please provide more details in the comments below)</span>
										</label>
										  </div>
										  <div class="form-check">
										<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox3_${convo_id}" type="checkbox" value="${checkFeedbackOptions[2]}">
										<label class="form-check-label" id="label3" for="checkbox3">
										Data source needs to be added or updated <br>
                						<span>(Please provide more details in the comments below)</span>
										</label>
										  </div>
										  
										  <textarea class="form-control" data-convo_id='${convo_id}' id="${convo_id}_convo_feedback_dislike_std" type="text" placeholder="Provide additional feedback"></textarea>
										<button id="send_dislike_feedback" data-convo_id='${convo_id}' class="btn  btn-custom  btn-success icon-wthot-bg btn-rounded mt-1" style="float: inline-end;" disabled><span><span>Send</span><span class="icon"><i class="fa fa-angle-right"></i> </span></span></button>
									</a>
									
								</div>
								<a id="enrollment_citation_btn_${convo_id}" data-convo_id='${convo_id}' style="margin: 2px 10px 2px 2px; margin-button: 15px; border: none; cursor: pointer"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16" style="margin-right: 5px;">
								<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
								<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
							  </svg>Citation</a>
							  <a id="copy_response_btn_env" data-convo_id='${convo_id}' style="margin: 2px 10px 2px 2px; margin-button: 15px; border: none; cursor: pointer"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16" style="margin-right: 5px;">
							  <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
							  </svg>Copy Response</a>
							  <a id="disclaimer" data-convo_id='${convo_id}' data-convo_action='disclaimer'  data-bs-toggle="dropdown"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
							<path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
							
						  </svg>Disclaimer</a>
						  
						  <div class="dropdown-menu dropdown-menu-end">
									<a class="dropdown-item">
									<p class="form-control" data-convo_id='${convo_id}' id="${convo_id}_disclaimer" type="text" style="border-color: red; white-space: normal;">
						  <strong>Disclaimer:</strong> While interacting in this chat window, you are interacting
						  with a chatbot that uses generative AI, or materials created by generative
						  AI. If you are ever transferred to a real person, our human representative
						  will introduce themselves during the chat. If you have any questions
						  regarding this notice, please email Algovernance@adtalem.com
					  </p>
									</a>
								</div>
							</div>
							</div>
								</div>
								
							</div>
							<div id="${convo_id}_cached_response_text" data-convo_id='${convo_id}' style="display: none; size: 16px" class="mt-2">
							<b>${window.env.studentAsStaffConfig.cachedResponseConstant}</b>
							</div>
							<div id="${convo_id}_apology_text" data-convo_id='${convo_id}' style="display: none; font-family: Halant; size: 16px" class="mt-2">
							<p>Sorry for the inadequate response. As a bot, I will use this feedback to get better!</p>
							</div>
							<div class="mt-2">
								${pdfCitationText}
								${websiteCitationText}
								${unverifiedText}
							</div>
						</div>
					</li>`).appendTo(".hk-chatbot-popup .chatbot-popup-body ul.list-unstyled");

					var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
					chatBox.scrollTop = chatBox.scrollHeight;
					if (inputText.includes('<!-- Cached Response -->') || inputText.includes('Cached Response')){
						findCatchResponse(convo_id);
					}
				}, 0);
			}
		}
	}
	return;
}

function likeConvoStudentAzureChatbot(e, id){
	var convoID = id.getAttribute('data-convo_id');
	var getDislikeButton = id.parentElement.children[3];
	if(getDislikeButton.style.color === 'rgb(5, 168, 135)') {
		getDislikeButton.style.color = null;
		var getApologyDiv = document.getElementById(convoID+"_apology_text");
		getApologyDiv.style.display = 'none';
	}
	id.style.color='#05a887';
	accessLikeTextareaByConvoId(convoID);
	document.getElementById(convoID+"_action_update").value = "like";
	console.log(document.getElementById(convoID+"_query"));
	var query = document.getElementById(convoID+"_query").textContent;
	var answer = document.getElementById(convoID+"_answer").textContent;
	$.ajax({
		type: "POST",
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		url: studenStaffConfig.convoAction,
		data: {
			chatbot_id: studenStaffConfig.chatBotId,
			session_id: localStorage.getItem('session_uuid'),
			convo_id: id.getAttribute('data-convo_id'),
			user:query,
			feedback: "",
			bot:answer,
			thumbs_up_down: id.getAttribute('data-convo_action')
		},
	});
}

function dislikeConvoStudentAzureChatbot(e, id){
	var getLikeButton = id.parentElement.children[1];
	if(getLikeButton.style.color === 'rgb(5, 168, 135)') {
		getLikeButton.style.color = null;
	}
	id.style.color='#05a887';
	var convoID = id.getAttribute('data-convo_id');
	accessDislikeTextareaByConvoIdForStd(convoID);
	document.getElementById(convoID+"_action_update").value = "dislike";
	var query = document.getElementById(convoID+"_query").textContent;
	var answer = document.getElementById(convoID+"_answer").textContent;
	$.ajax({
		type: "POST",
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		url: studenStaffConfig.convoAction,
		data: {
			chatbot_id: studenStaffConfig.chatBotId,
			session_id: localStorage.getItem('session_uuid'),
			convo_id: id.getAttribute("data-convo_id"),
			user:query,
			feedback: "",
			bot:answer,
			thumbs_up_down: id.getAttribute("data-convo_action")
		}
	});
}

function sendDislikeFeedbackStudentAzureChatbot(e, id){
	var checkedLabels = [];
	var convoId = id.getAttribute("data-convo_id");
	for(let i=1;i<=3;i++){
		var checkbox = document.getElementById(`checkbox${i}_${convoId}`);
		if(checkbox.checked){
			checkedLabels.push(checkbox.value);
		}
	}
	var value = document.getElementById(convoId+"_convo_feedback_dislike_std").value;
	var query = document.getElementById(convoId+"_query").textContent;
	var answer = document.getElementById(convoId+"_answer").textContent;
	var checkedLabels = checkedLabels.toString();
	var action = document.getElementById(convoId+"_action_update").value;
	console.log('convo feedback student: ', studenStaffConfig.convoFeedback);
	setTimeout(function(){
		$.ajax({
			type: "POST",
			url: studenStaffConfig.convoFeedback,
			data: {
				chatbot_id: studenStaffConfig.chatBotId,
				session_id: localStorage.getItem('session_uuid'),
				convo_id: convoId,
				feedback: value,
				feedback_category:checkedLabels,
				user:query,
				bot:answer,
				thumbs_up_down:action
			}
		});
	},0);
	var accessApologyDiv = document.getElementById(convoId+"_apology_text");
	accessApologyDiv.style.display = 'block';
	$(`#dislikeCategory_${convoId}`).removeClass('show');
	isDislikePopupOpen = false;
	inputTextStatus(true);
	return;
}

function sendLikeFeedbackStudentAzureChatbot(e, id){
	var convoId = id.getAttribute("data-convo_id");
	var value = document.getElementById(convoId+"_convo_feedback_like").value;
	var query = document.getElementById(convoId+"_query").textContent;
	var answer = document.getElementById(convoId+"_answer").textContent;
	var action = document.getElementById(convoId+"_action_update").value;
	setTimeout(function(){
		$.ajax({
			type: "POST",
			url: studenStaffConfig.convoFeedback,
			data: {
				chatbot_id: studenStaffConfig.chatBotId,
				session_id: localStorage.getItem('session_uuid'),
				convo_id: convoId,
				feedback: value,
				user:query,
				bot:answer,
				thumbs_up_down:action
			}
		});
	},0);
	inputTextStatus(true);
	return;
}


$(document).on('click', "#student_citation_btn", function (e) {
	var convoId = $(this).data('convo_id');
	var citation_id = "student_citation_msg_box_" + convoId;
	var citationMsgBox = document.getElementById(citation_id);
	document.getElementById(citation_id).style.display =  (citationMsgBox.style.display == 'block' ) ? 'none' :  'block';
	var unverified_citation_id = "student_unverified_citation_msg_box";
	var unverifiedCitationMsgBox = document.getElementById(unverified_citation_id);
	document.getElementById(unverified_citation_id).style.display =  (unverifiedCitationMsgBox.style.display == 'block' ) ? 'none' :  'block';
  });

$(document).on('click', "#copy_response_btn_stdnt", function (e) {
	var convoId = $(this).data('convo_id');
	var responseCopy = document.getElementById(convoId+"_response");
	var responseContent = responseCopy.innerText;
	navigator.clipboard.writeText(responseContent).then(function(){
		console.log("Content copied...");
	}).catch(function(err){
		console.log("Failed to copy content : ",err);
	})
});

function loadHistoryForStudent(){
	var jsonData = {
		chatbot_id: studenStaffConfig.chatBotId,
		userEmail:sessionStorage.getItem('userEmail'),
		group_by_date: "yes"
	};
	$.ajax({
		type: 'POST',
		url: studenStaffConfig.chatHistory,
		contentType:'application/json',
		data: JSON.stringify(jsonData),
		success: function (response) {
			var reorderRes = response.chat_history;
            let reversedObject = {};
            let keys = Object.keys(reorderRes).sort((a, b) => new Date(b) - new Date(a));
            keys.forEach(key => {
                reversedObject[key] = reorderRes[key];
            });
            var lengthRes = Object.keys(reversedObject).length;
			let user_name = sessionStorage.getItem('userName');
			user_name = user_name.replace(",","");
			let userName = user_name.split(" ");
			var chatHistoryTitle = document.getElementById("chat-history-title");
			chatHistoryTitle.innerText = "Chat History - "+userName[1]+" "+userName[0];
			if(lengthRes >0){
				$('.noHistory').remove()
				loadChatHistory(reversedObject);
			}else{
				if(!noHistory){
					var noHistoryContainer = document.getElementById("chat_history");
					var noHistoryTag = document.createElement("div");
					noHistoryTag.classList.add("noHistory");
					noHistoryTag.innerText = "No History...";
					noHistoryContainer.appendChild(noHistoryTag);
					noHistory = true;
				}
				console.log("No history in database.");
			}
		},
		error: function (data) {
			var chatHistoryError = document.getElementById('chat-history-title');
			chatHistoryError.innerText = "Failed to load history. Please try again..."
			console.log("History : ",data);
		}
	});
	const messageInputField = document.getElementById("input_msg_chat_popup");
	messageInputField.value = '';
	updateSendButton();
	
}
function loadChatHistory(data){
	let i = 0;
	for(const date in data){
		var historyForDate =data[date];
		var queryDate = date;
		var historyTagId = "historyDateDivison"+i;
		var queriesTagId = "queriesTagId"+i;
		i++;
		var chatHistoryContainer = document.getElementById("chat_history");
		var removeHistoryContainer = document.getElementById(historyTagId);
		if(removeHistoryContainer){
			chatHistoryContainer.removeChild(removeHistoryContainer);
		}
		var historyDateDiv = document.createElement("div");
		historyDateDiv.setAttribute("id",historyTagId)
		chatHistoryContainer.appendChild(historyDateDiv);
		var dateTag = document.createElement("h6");
		dateTag.style.fontFamily = 'Halant';
		dateTag.style.fontSize = '15px';
		dateTag.style.color = "#000000";
		dateTag.style.fontWeight = "800"
		var queriesTag = document.createElement("div");
		queriesTag.setAttribute("id",queriesTagId);
		queriesTag.style.display = "grid";
		queriesTag.style.fontFamily = 'Halant';
		queriesTag.style.fontSize = '15px';
		queriesTag.style.marginTop = "0.3rem";
		queriesTag.style.marginBottom = "2rem";
		const formattedDate = formatDate(queryDate);
		dateTag.textContent = formattedDate;
		var dateHistoryContainer = document.getElementById(historyTagId);
		dateHistoryContainer.appendChild(dateTag);
		dateHistoryContainer.appendChild(queriesTag);
		historyForDate.forEach(function(history){
			historySetup(queriesTagId, history.user);
		})
	}
}
function historySetup(id,query){
	var queriesContainer = document.getElementById(id);
	var queryTag = document.createElement("button");
	queryTag.textContent = query;
	queryTag.classList.add("btn");
	queryTag.classList.add("history-query");
	queryTag.style.border= "none";
	queryTag.style.padding= '2px 0';
	queryTag.style.textAlign= "start";
	queryTag.style.textDecoration= "underline";
	queryTag.style.color= "#0601FA";
	queriesContainer.appendChild(queryTag);
}
function formatDate(dateString) {
	dateArray = dateString.split("-");
	// Array of month names
	const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	// Get the day and month
	const day = dateArray[2];
	const month = dateArray[1]-1;
	// Function to get the suffix for the day
	function getDaySuffix(day) {
	  if (day > 3 && day < 21) return "th";
	  switch (day % 10) {
		case 1:  return "st";
		case 2:  return "nd";
		case 3:  return "rd";
		default: return "th";
	  }
	}
	// Format the day with suffix
	const formattedDay = day + getDaySuffix(day);
	// Get the month name
	const formattedMonth = monthNames[month];
	// Combine and return the formatted date
	return formattedDay + " " + formattedMonth;
}
function activeHistoryTab(){
	$('#history_tab .bottom_border').css('display','block');
	$('#faq_tab .bottom_border').css('display','none');
	$('#faqTab').css('display','none');
	$('#historyTab').css('display','block');
	$('#faq_tab').css('color','#C4C4C4');
	$('#history_tab').css('color','#00334C');
}

$(document).on('click', "#faq_tab", function(e){
	$('#faq_tab .bottom_border').css('display','block');
	$('#history_tab .bottom_border').css('display','none');
	$('#historyTab').css('display','none');
	$('#faqTab').css('display','block');
	$('#history_tab').css('color','#C4C4C4');
	$('#faq_tab').css('color','#00334C');
})
$(document).on('click', "#history_tab", function(e){
	activeHistoryTab();
});

//Method to disable/enable input test area to ask query
function inputTextStatus(status){
	var inputTag = document.getElementById("input_msg_chat_popup");
	if(!status){
		inputTag.disabled = true;
		inputTag.placeholder = "please provide your feedback to continue.";
		var getSendBtn = document.getElementById('send_btn_icon');
		getSendBtn.setAttribute('fill', '#EBEBEB');
		localStorage.setItem('feedbackprovide', 'false');
		var getButton = document.getElementById('refresh_chat');
		getButton.disabled = true;
		// getButton.style.pointerEvents = 'none';
		getButton.style.cursor = 'not-allowed';
		var getHistoryButton = document.getElementById('history_chat');
		getHistoryButton.disabled = true;
		getHistoryButton.style.cursor = 'not-allowed';
	} else {
		inputTag.disabled = false;
		inputTag.placeholder = "Ask me anything...";
		localStorage.setItem('feedbackprovide', 'true');
		var getButton = document.getElementById('refresh_chat');
		getButton.style.cursor = 'pointer';
		getButton.disabled = false;
		var getHistoryButton = document.getElementById('history_chat');
		getHistoryButton.disabled = false;
		getHistoryButton.style.cursor = 'pointer';
		// var getSendBtn = document.getElementById('send_btn_icon');
		// getSendBtn.setAttribute('fill', '#003a53');
	}
}

function changeSendButtonColorForStd() {
	var inputTag = document.getElementById("input_msg_chat_popup");
		inputTag.addEventListener('keydown', updateSendButton);
		inputTag.addEventListener('keyup', updateSendButton);
}

function updateSendButton() {
	var inputTag = document.getElementById("input_msg_chat_popup");
	if (inputTag.value.trim() !== '') {
		var getSendBtn = document.getElementById('send_btn_icon');
		getSendBtn.setAttribute('fill', '#003a53');
	} else {
		var getSendBtn = document.getElementById('send_btn_icon');
		getSendBtn.setAttribute('fill', '#EBEBEB');
	}
}

function accessLikeTextareaByConvoId(convoId) {
	var getTextArea = document.getElementById(convoId + "_convo_feedback_like");
	getSendLikeButton = getTextArea.nextElementSibling;
	getTextArea.addEventListener('input', function (e) {
		if (getTextArea.value.trim() !== '') {
			getSendLikeButton.disabled = false;
		} else {
			getSendLikeButton.disabled = true;
		}
	})
}

function accessDislikeTextareaByConvoIdForStd(convoId) {
	var getTextArea = document.getElementById(convoId + "_convo_feedback_dislike_std");
	var getSendDislikeButton = getTextArea.nextElementSibling;
	var checkboxCount = 0;
	$(".form-check-input").on("click", function(){
		if($(this).prop('checked')){
			 checkboxCount++;
		  } else {
			  checkboxCount--;
		  }
		  if($(this).prop('unchecked')){
		}
		  if (checkboxCount > 0) {
			getSendDislikeButton.disabled = false;
		  } else {
			getSendDislikeButton.disabled = true;
		  }
	  }); 
}

$(document).on('click', ".dislikeCategory", function(e){
	e.stopPropagation();
});

$(document).on('click', function(e){
	$(`#dislikeCategory_${convoId}`).removeClass('show');
	isDislikePopupOpen = false;
});

$(document).on('click', "[id^='enrollment_citation_btn_']", function (e) {
	var convoId = $(this).data('convo_id');
	var citation_id = "enrollment_citation_msg_box_" + convoId;
	var citationMsgBox = document.getElementById(citation_id);
	if(citationMsgBox){
		document.getElementById(citation_id).style.display =  (citationMsgBox.style.display == 'block' ) ? 'block' :  'none';
	}
	var unverified_citation_id = "enrollment_unverified_citation_msg_box_" + convoId;
	var unverifiedCitationMsgBox = document.getElementById(unverified_citation_id);
	if(unverifiedCitationMsgBox){
		document.getElementById(unverified_citation_id).style.display =  (unverifiedCitationMsgBox.style.display == 'block' ) ? 'none' :  'block';
	}
	var pdf_citation_id = "enrollment_pdf_citation_msg_box_" + convoId;
	var pdfCitationMsgBox = document.getElementById(pdf_citation_id);
	if(pdfCitationMsgBox) {
		console.log('in pdf citation block');
		document.getElementById(pdf_citation_id).style.display = (pdfCitationMsgBox.style.display == 'block') ? 'none' : 'block';
	}
});

function findCatchResponse(convoId) {
	var getCitationButton = document.getElementById('enrollment_citation_btn_' + convoId);
	getCitationButton.style.disabled = true;
	getCitationButton.style.cursor = 'not-allowed';
	var getCachedResponseText = document.getElementById(convoId + "_cached_response_text");
	getCachedResponseText.style.display = 'block';
	
}

function removeCitationLinksFromResponse(inputText) {
	const urlPattern = /\[https?:\/\/[^\s\[\]]+\]/g;
	return inputText.replace(urlPattern, '');
}

// window.onbeforeunload = (event) => {const e = event;
// 	var status = localStorage.getItem('feedbackprovide');
// 	console.log('status window onload: ', status);
// 	if (status == 'true' && e) {
// 		console.log('e: ', e);
// 		e.returnValue = ''; // Legacy method for cross browser support
// 		}
// 		return 'dont reload'; 
// 		// Legacy method for cross browser support
// 		};

// window.addEventListener('beforeunload', function (event) {
//     // Check if feedbackprovide is set to false
//     if (localStorage.getItem('feedbackprovide') === 'false') {
//         // Prevent the reload and show a custom message
//         event.preventDefault();
//         event.returnValue = '';  // This will trigger the default browser message
//     }
// });